import { compress } from "micro-graphql-react"

export default compress`
  query($id: ID!) {
    tailoredAssignment(id: $id) {
      id
      tailoredSections {
        id
        name
        tailoredQuestions {
          id
          type
          text
          image {
            fileName
            url
          }
          diagram {
            fileName
            url
          }
          tailoredAnswers {
            id
            type
            text
            correct
            image {
              fileName
              url
            }
          }
        }
      }
    }
  }
`
