import React from "react"

import { Form, Formik } from "formik"
import PropTypes from "prop-types"
import * as Yup from "yup"

import axios from "src/axios"

import { ErrorMessage } from "components/Forms/Formik"

import QuestionOptions from "./QuestionOptions"

const TailoredSectionForm = props => {
  const { id, apiNamespace, formState, maxQuestions, tags } = props

  const handleSubmit = (values, actions) => {
    axios
      .patch(`/api/${apiNamespace}/tailored_sections/${id}`, {
        tailored_section: {
          tailored_question_ids: values.questionIds,
        },
      })
      .then(response => {
        const json = response.data
        if (json.success === true) {
          window.location = json.redirectTo
        } else {
          alert(
            `Unable to save assignment. Please ensure there are no validation errors and resubmit: ${json.errors.join(
              ", "
            )}`
          )
          actions.setSubmitting(false)
        }
      })
  }

  const sectionSchema = formikProps => {
    return Yup.object().shape({
      questionIds: Yup.array().max(
        maxQuestions,
        "Please select at most {max} questions"
      ),
    })
  }

  return (
    <Formik
      initialValues={formState}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={sectionSchema}
    >
      {({ values, status, isSubmitting }) => (
        <Form>
          <h2>Possible Questions:</h2>
          <QuestionOptions
            apiNamespace={apiNamespace}
            values={values}
            tags={tags}
          />
          <div className="row">
            <ErrorMessage name="questionIds" />
          </div>
          <div className="row">
            <div className="col-1">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-success solid green"
              >
                Save
              </button>
            </div>
            <div className="col-2 d-flex align-items-center">
              {status && status.msg && <div>{status.msg}</div>}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

TailoredSectionForm.propTypes = {
  apiNamespace: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  maxQuestions: PropTypes.number.isRequired,
  tags: PropTypes.array.isRequired,
  formState: PropTypes.shape({
    questionIds: PropTypes.array.isRequired,
  }).isRequired,
}

export default TailoredSectionForm
