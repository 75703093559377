import React from "react"

import TailoredQuestionPreview from "./TailoredQuestion"

const TailoredSection = props => {
  const { name, tailoredQuestions } = props.tailoredSection

  return (
    <React.Fragment>
      <h3>{name}</h3>
      {tailoredQuestions.map(tailoredQuestion => (
        <TailoredQuestionPreview
          key={tailoredQuestion.id}
          tailoredQuestion={tailoredQuestion}
        />
      ))}
    </React.Fragment>
  )
}

export default TailoredSection
