import React from "react"

import { NotificationContainer } from "react-notifications"

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { foreignLanguageFluencyOptions } from "src/enums"
import ordered from "src/ordered"

import { gql, useQuery } from "hooks/urql"

import ClipboardButton from "components/ClipboardButton"
import LocalTime from "components/LocalTime"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import ApproveApplicant from "./ApproveApplicant"
import ApproveInterview from "./ApproveInterview"
import ApproveResume from "./ApproveResume"
import ApproveSelfie from "./ApproveSelfie"
import DeferApplicant from "./DeferApplicant"
import EditAdminNotes from "./EditAdminNotes"
import RejectInterview from "./RejectInterview"
import RejectResume from "./RejectResume"
import RejectSelfie from "./RejectSelfie"
import ResendInterviewInstructions from "./ResendInterviewInstructions"
import ResendOnboardingInstructions from "./ResendOnboardingInstructions"
import ResendSelfieInstructions from "./ResendSelfieInstructions"
import RevertToDraft from "./RevertToDraft"
import RevertToInterviewScheduled from "./RevertToInterviewScheduled"
import RevertToResumeSubmitted from "./RevertToResumeSubmitted"
import RevertToSelfieSubmitted from "./RevertToSelfieSubmitted"
import StartResumeReview from "./StartResumeReview"
import StartSelfieReview from "./StartSelfieReview"

import "react-notifications/lib/notifications.css"

const YesNoMark = ({ value }) =>
  value ? (
    <FontAwesomeIcon icon={faCheck} className="text-success" />
  ) : (
    <FontAwesomeIcon icon={faTimes} className="text-danger" />
  )

const WorkflowRow = ({ children, rejection }) => (
  <div
    className={`align-items-center my-1 grid grid-cols-2 px-2 py-1 ${
      rejection ? "rounded bg-pink-300" : ""
    }`}
  >
    {children}
  </div>
)

const SubjectBucketsDisplay = ({ subjectBuckets }) => (
  <ul className="list-unstyled">
    {ordered(ordered(subjectBuckets, "name"), "tier", "order").map(
      subjectBucket => (
        <li key={subjectBucket.id}>
          {subjectBucket.tier.gradeRange}: {subjectBucket.name}
        </li>
      )
    )}
  </ul>
)

const TutorApplicationDetails = ({ id }) => {
  const [result] = useQuery({ query: tutorApplicationQuery, variables: { id } })

  return (
    <>
      <NotificationContainer />
      <UrqlLoadingIndicator result={result}>
        {({ data: { tutorApplication } }) => (
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
            <div className="order-2 md:order-1">
              <h3>Application Details</h3>

              <dl>
                <dt>Current State</dt>
                <dd>{tutorApplication.applicationState}</dd>

                <dt>Applicant URL</dt>
                <dd>
                  <p className="mr-5 truncate">
                    {tutorApplication.editUrl}
                    <br />
                    <ClipboardButton
                      content={tutorApplication.editUrl}
                      className="btn btn-sm btn-info"
                    >
                      Click to copy URL
                    </ClipboardButton>
                  </p>
                </dd>
              </dl>

              <hr />

              <a
                href={`${document.location.pathname}/edit`}
                className="btn btn-info"
              >
                Edit Application
              </a>

              <dl>
                <dt>Email</dt>
                <dd>{tutorApplication.email}</dd>

                <dt>Resume</dt>
                <dd>
                  {tutorApplication.resume && (
                    <a
                      href={tutorApplication.resume.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {tutorApplication.resume.filename}
                    </a>
                  )}
                </dd>

                <dt>Selfie</dt>
                <dd>
                  {tutorApplication.selfie && (
                    <a
                      href={tutorApplication.selfie.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {tutorApplication.selfie.filename}
                    </a>
                  )}

                  {tutorApplication.selfieExternalUrl && (
                    <a
                      href={tutorApplication.selfieExternalUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      External Video Link
                    </a>
                  )}
                </dd>

                <dt>Referral Source</dt>
                <dd>
                  {tutorApplication.referralSource}
                  {tutorApplication.referralSourceOther && (
                    <span> ({tutorApplication.referralSourceOther})</span>
                  )}
                </dd>

                <dt>First Name</dt>
                <dd>{tutorApplication.firstName}</dd>

                <dt>Last Name</dt>
                <dd>{tutorApplication.lastName}</dd>

                <dt>Phone Number</dt>
                <dd>{tutorApplication.phoneNumber}</dd>

                <dt>Country</dt>
                <dd>{tutorApplication.country}</dd>

                <dt>State/Region</dt>
                <dd>{tutorApplication.region}</dd>

                <dt>Time Zone</dt>
                <dd>{tutorApplication.timeZone}</dd>
              </dl>

              <h4>Degrees</h4>
              {tutorApplication.noCollegeEducation ? (
                <p className="text-lg font-bold">No college education</p>
              ) : (
                <table className="table-striped table">
                  <thead className="text-primary">
                    <tr>
                      <th>Degree</th>
                      <th>College</th>
                      <th>Year</th>
                      <th>Level</th>
                      <th>Country</th>
                      <th>Diploma Mill</th>
                      <th>Transcript</th>
                    </tr>
                  </thead>

                  <tbody>
                    {tutorApplication.degrees.map(degree => (
                      <tr key={degree.id}>
                        <td>
                          {degree.degree} ({degree.degreeType})
                        </td>
                        <td>{degree.college}</td>
                        <td>{degree.year}</td>
                        <td>
                          {degree.level}
                          {degree.levelOther && (
                            <span> ({degree.levelOther})</span>
                          )}
                        </td>
                        <td>{degree.country}</td>
                        <td>
                          <YesNoMark value={degree.diplomaMill} />
                        </td>
                        <td>
                          {degree.transcript?.url && (
                            <>
                              <a href={degree.transcript.downloadUrl}>
                                Download
                              </a>
                              <br />
                              <a
                                href={degree.transcript.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View in browser
                              </a>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              <h4>Certificates</h4>
              <ul className="list-unstyled">
                {tutorApplication.certificates.map(certificate => (
                  <li key={certificate}>{certificate}</li>
                ))}
              </ul>

              <h4>Foreign Language Proficiencies</h4>
              <blockquote className="border-top-0 border-r-0 border-b-0 border-l-2 border-solid border-blue-300 bg-gray-200 py-3 pl-3 italic">
                <h6>Academic Ability Guide</h6>
                1: Unable to teach in this language
                <br />
                ...
                <br />
                5: Confident in teaching this language
              </blockquote>
              <table className="table-striped table">
                <thead className="text-primary">
                  <tr>
                    <th>Language</th>
                    <th>Fluency</th>
                    <th>Academic Ability</th>
                  </tr>
                </thead>

                <tbody>
                  {ordered(
                    tutorApplication.foreignLanguageProficiencies,
                    "name"
                  ).map(proficiency => (
                    <tr key={proficiency.id}>
                      <td>{proficiency.foreignLanguage.name}</td>
                      <td>
                        {
                          foreignLanguageFluencyOptions.find(
                            h => h.value === proficiency.fluency
                          )?.label
                        }
                      </td>
                      <td>{proficiency.academicAbility}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <h4>Background Checks</h4>
              <dl>
                <dt>Can Complete Fingerprint within 2 weeks</dt>
                <dd>
                  <YesNoMark value={tutorApplication.canCompleteFingerprint} />
                </dd>

                <dt>Background Check Notes</dt>
                <dd>{tutorApplication.backgroundCheckNotes}</dd>
              </dl>

              <h4>Technology</h4>
              <dl>
                <dt>Tech Savviness</dt>
                <dd>{tutorApplication.techSavviness}</dd>

                {tutorApplication.techSavviness < 4 && (
                  <>
                    <dt>Can download/upload files</dt>
                    <dd>
                      <YesNoMark value={tutorApplication.canDownloadFiles} />
                    </dd>

                    <dt>Can use google drive, gmail etc.</dt>
                    <dd>
                      <YesNoMark value={tutorApplication.canUseCommonWebapps} />
                    </dd>

                    <dt>Can troubleshoot camera/mic issues</dt>
                    <dd>
                      <YesNoMark
                        value={tutorApplication.canTroubleshootWebcam}
                      />
                    </dd>

                    <dt>Can troubleshoot login issues</dt>
                    <dd>
                      <YesNoMark
                        value={tutorApplication.canTroubleshootLogin}
                      />
                    </dd>
                  </>
                )}

                <dt>High Speed Internet Access</dt>
                <dd>
                  <YesNoMark value={tutorApplication.highSpeedInternet} />
                </dd>

                <dt>Owns a tablet-like device</dt>
                <dd>
                  <YesNoMark value={tutorApplication.hasTablet} />
                </dd>

                {!tutorApplication.hasTablet && (
                  <>
                    <dt>Will Purchase Tablet</dt>
                    <dd>
                      <YesNoMark value={tutorApplication.willPurchaseTablet} />
                    </dd>
                  </>
                )}
              </dl>

              <h4>Teaching Experience</h4>
              <dl>
                <dt>Teaching Experience</dt>
                <dd>{tutorApplication.teachingExperienceYears} years</dd>

                <dt>Years Since Last Teaching Experience</dt>
                <dd>
                  {tutorApplication.yearsSinceLastTeachingExperience} year(s)
                  ago
                </dd>
              </dl>

              <h5>Grade-Specific Experience</h5>
              <blockquote className="border-top-0 border-r-0 border-b-0 border-l-2 border-solid border-blue-300 bg-gray-200 py-3 pl-3 italic">
                1: None/Limited
                <br />
                ...
                <br />
                5: Extensive
              </blockquote>
              <table className="table-striped table">
                <thead className="text-primary">
                  <tr>
                    <th>Grade Range</th>
                    <th>Teaching Experience</th>
                    <th>Notes</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>K-2</td>
                    <td>{tutorApplication.teachingExperienceK2}</td>
                    <td>{tutorApplication.teachingExperienceK2Other}</td>
                  </tr>

                  <tr>
                    <td>3-5</td>
                    <td>{tutorApplication.teachingExperience35}</td>
                    <td>{tutorApplication.teachingExperience35Other}</td>
                  </tr>

                  <tr>
                    <td>6-8</td>
                    <td>{tutorApplication.teachingExperience68}</td>
                    <td>{tutorApplication.teachingExperience68Other}</td>
                  </tr>

                  <tr>
                    <td>9-12</td>
                    <td>{tutorApplication.teachingExperience912}</td>
                    <td>{tutorApplication.teachingExperience912Other}</td>
                  </tr>

                  <tr>
                    <td>College</td>
                    <td>{tutorApplication.teachingExperienceCollege}</td>
                    <td>{tutorApplication.teachingExperienceCollegeOther}</td>
                  </tr>

                  <tr>
                    <td>Learning Differences</td>
                    <td>
                      {tutorApplication.learningDifferencesTeachingAbility}
                    </td>
                    <td>
                      {tutorApplication.learningDifferencesExperienceNotes}
                    </td>
                  </tr>

                  <tr>
                    <td>Behavioral Differences</td>
                    <td>
                      {tutorApplication.behavioralDifferencesTeachingAbility}
                    </td>
                    <td>
                      {tutorApplication.behavioralDifferencesExperienceNotes}
                    </td>
                  </tr>

                  <tr>
                    <td>English Language Learners</td>
                    <td>
                      {tutorApplication.englishLanguageLearnersTeachingAbility}
                    </td>
                    <td>
                      {
                        tutorApplication.englishLanguageLearnersTeachingExperienceNotes
                      }
                    </td>
                  </tr>

                  <tr>
                    <td>Parent Communication Ability</td>
                    <td>{tutorApplication.parentCommunicationAbility}</td>
                    <td />
                  </tr>
                </tbody>
              </table>

              <div className="mb-4">
                <h6>Teaching Style</h6>
                <p>{tutorApplication.teachingStyle}</p>
              </div>

              <div className="mb-5">
                <h6>Teaching Reason</h6>
                <p>{tutorApplication.teachingReason}</p>
              </div>

              <div className="mb-5">
                <h6>Camaraderie Building</h6>
                <p>{tutorApplication.camraderieNotes}</p>
              </div>

              <h4>Subjects</h4>
              <h5>Math</h5>
              <SubjectBucketsDisplay
                subjectBuckets={tutorApplication.mathSubjectBuckets}
              />

              <h5>Science</h5>
              <SubjectBucketsDisplay
                subjectBuckets={tutorApplication.scienceSubjectBuckets}
              />

              <h5>English</h5>
              <SubjectBucketsDisplay
                subjectBuckets={tutorApplication.englishSubjectBuckets}
              />

              <h5>Social Studies</h5>
              <SubjectBucketsDisplay
                subjectBuckets={tutorApplication.socialStudiesSubjectBuckets}
              />

              <h5>Languages</h5>
              <SubjectBucketsDisplay
                subjectBuckets={tutorApplication.languagesSubjectBuckets}
              />

              <h5>Other</h5>
              <SubjectBucketsDisplay
                subjectBuckets={tutorApplication.otherSubjectBuckets}
              />

              <h4>Availabilities</h4>
              <ul className="list-unstyled">
                {ordered(
                  ordered(tutorApplication.availabilities, "weekday"),
                  "startsAt"
                ).map(availability => (
                  <li key={availability.id}>
                    <LocalTime
                      timestamp={availability.startsAt}
                      format="dddd LT"
                    />{" "}
                    &mdash;{" "}
                    <LocalTime timestamp={availability.endsAt} omitDate />
                  </li>
                ))}
              </ul>
            </div>

            <div className="order-1 md:order-2">
              <h3>Workflow</h3>

              {tutorApplication.tutor && (
                <>
                  <h5>Approved!</h5>
                  <p>
                    <a
                      href={tutorApplication.tutor.showPath}
                      className="btn btn-info"
                    >
                      View Tutor
                    </a>
                  </p>
                </>
              )}

              <div className="d-inline-block mb-4 rounded bg-sky-200 p-3 pr-5">
                <p className="font-medium">
                  The following auto-rejection rules were run:
                </p>
                <ul className="mb-0 italic">
                  <li>No degrees came from a non-profit</li>
                  <li>No degree from a US college/university</li>
                  <li>No college education</li>
                  <li>Non-US residency</li>
                  <li>At least 3 years of teaching experience</li>
                  <li>Insufficient tech savviness</li>
                  <li>At least 2 weekday availability slots</li>
                  <li>Has taught in the last 5 years</li>
                </ul>
              </div>

              <dl>
                <dt>Current Assignee</dt>
                <dd>
                  {tutorApplication.currentAssignee ? (
                    <span>
                      {tutorApplication.currentAssignee.fullName} (
                      {tutorApplication.currentAssignee.__typename})
                    </span>
                  ) : (
                    <span>None</span>
                  )}
                </dd>
              </dl>

              <section>
                <h4>Admin Notes</h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: tutorApplication.formattedAdminNotes,
                  }}
                />
                <div>
                  <EditAdminNotes tutorApplication={tutorApplication} />
                </div>
              </section>

              <section>
                <h4>Resume Review</h4>

                <WorkflowRow>
                  <span className="font-medium">Submitted</span>
                  <LocalTime timestamp={tutorApplication.resumeSubmittedAt} />
                </WorkflowRow>

                {tutorApplication.resumeReviewStartedAt && (
                  <>
                    <WorkflowRow>
                      <span className="font-medium">Review Started</span>
                      <LocalTime
                        timestamp={tutorApplication.resumeReviewStartedAt}
                      />
                    </WorkflowRow>

                    {tutorApplication.resumeReviewer && (
                      <WorkflowRow>
                        <span className="font-medium">Under Review by</span>
                        {tutorApplication.resumeReviewer?.fullName}
                      </WorkflowRow>
                    )}
                  </>
                )}

                {tutorApplication.mayRevertToDraft && (
                  <div>
                    <RevertToDraft id={id} />
                  </div>
                )}
                {tutorApplication.mayStartResumeReview && (
                  <div>
                    <StartResumeReview id={id} />
                  </div>
                )}
                {tutorApplication.mayApproveResume && (
                  <div>
                    <ApproveResume id={id} />
                  </div>
                )}
                {tutorApplication.mayRejectResume && (
                  <div>
                    <RejectResume id={id} />
                  </div>
                )}
                {tutorApplication.mayRevertToResumeSubmitted && (
                  <div>
                    <RevertToResumeSubmitted id={id} />
                  </div>
                )}

                {tutorApplication.resumeApprovedAt && (
                  <WorkflowRow>
                    <span className="font-medium">Approved</span>
                    <LocalTime timestamp={tutorApplication.resumeApprovedAt} />
                  </WorkflowRow>
                )}

                {tutorApplication.resumeRejectedAt && (
                  <>
                    <WorkflowRow>
                      <span className="font-medium">Rejected</span>
                      <LocalTime
                        timestamp={tutorApplication.resumeRejectedAt}
                      />
                    </WorkflowRow>

                    {tutorApplication.resumeRejectedBy && (
                      <WorkflowRow>
                        <span className="font-medium">Rejected By</span>
                        {tutorApplication.resumeRejectedBy?.fullName}
                      </WorkflowRow>
                    )}

                    <WorkflowRow rejection>
                      <span className="font-medium">Rejection Reason</span>
                      <p className="m-0">
                        {tutorApplication.resumeRejectionReason}
                      </p>
                    </WorkflowRow>
                  </>
                )}
                <hr />
              </section>

              <section>
                <h4>Selfie Review</h4>

                {tutorApplication.applicationState === "pending_selfie" && (
                  <ResendSelfieInstructions id={id} />
                )}
                {tutorApplication.selfieSubmittedAt && (
                  <WorkflowRow>
                    <span className="font-medium">Submitted</span>
                    <LocalTime timestamp={tutorApplication.selfieSubmittedAt} />
                  </WorkflowRow>
                )}

                {tutorApplication.selfieReviewStartedAt && (
                  <>
                    <WorkflowRow>
                      <span className="font-medium">Review Started</span>
                      <LocalTime
                        timestamp={tutorApplication.selfieReviewStartedAt}
                      />
                    </WorkflowRow>

                    <WorkflowRow>
                      <span className="font-medium">Under Review by</span>
                      {tutorApplication.selfieReviewer?.fullName}
                    </WorkflowRow>
                  </>
                )}

                {tutorApplication.mayStartSelfieReview && (
                  <div>
                    <StartSelfieReview id={id} />
                  </div>
                )}
                {tutorApplication.mayApproveSelfie && (
                  <div>
                    <ApproveSelfie id={id} />
                  </div>
                )}
                {tutorApplication.mayRejectSelfie && (
                  <div>
                    <RejectSelfie id={id} />
                  </div>
                )}
                {tutorApplication.mayRevertToSelfieSubmitted && (
                  <div>
                    <RevertToSelfieSubmitted id={id} />
                  </div>
                )}

                {tutorApplication.selfieApprovedAt && (
                  <WorkflowRow>
                    <span className="font-medium">Approved</span>
                    <LocalTime timestamp={tutorApplication.selfieApprovedAt} />
                  </WorkflowRow>
                )}

                {tutorApplication.selfieRejectedAt && (
                  <>
                    <WorkflowRow>
                      <span className="font-medium">Rejected</span>
                      <LocalTime
                        timestamp={tutorApplication.selfieRejectedAt}
                      />
                    </WorkflowRow>

                    <WorkflowRow>
                      <span className="font-medium">Rejected By</span>
                      {tutorApplication.selfieRejectedBy?.fullName}
                    </WorkflowRow>

                    <WorkflowRow rejection>
                      <span className="font-medium">Rejection Reason</span>
                      {tutorApplication.selfieRejectionReason}
                    </WorkflowRow>
                  </>
                )}
                <hr />
              </section>

              <section>
                <h4>Interview</h4>
                {tutorApplication.applicationState === "pending_interview" && (
                  <ResendInterviewInstructions id={id} />
                )}
                {tutorApplication.tutorApplicationInterviewer && (
                  <>
                    <WorkflowRow>
                      <span className="font-medium">Type/Name</span>
                      {tutorApplication.tutorApplicationInterviewer.name}
                    </WorkflowRow>

                    <WorkflowRow>
                      <span className="font-medium">Interviewer</span>
                      {
                        tutorApplication.tutorApplicationInterviewer.admin
                          .fullName
                      }
                    </WorkflowRow>

                    <WorkflowRow>
                      <span className="font-medium">Scheduled For</span>
                      <LocalTime
                        timestamp={tutorApplication.interviewScheduledFor}
                      />
                    </WorkflowRow>

                    <WorkflowRow>
                      <span className="font-medium">Scheduled At</span>
                      <LocalTime
                        timestamp={tutorApplication.interviewScheduledAt}
                      />
                    </WorkflowRow>

                    <WorkflowRow>
                      <span className="font-medium">Applicant Notes</span>
                      {tutorApplication.interviewApplicantNotes}
                    </WorkflowRow>
                  </>
                )}

                {tutorApplication.interviewRejectedAt && (
                  <>
                    <WorkflowRow>
                      <span className="font-medium">Rejected</span>
                      <LocalTime
                        timestamp={tutorApplication.interviewRejectedAt}
                      />
                    </WorkflowRow>

                    <WorkflowRow>
                      <span className="font-medium">Rejected By</span>
                      {tutorApplication.interviewRejectedBy?.fullName}
                    </WorkflowRow>

                    <WorkflowRow rejection>
                      <span className="font-medium">Rejection Reason</span>
                      <p className="m-0">
                        {tutorApplication.interviewRejectionReason}
                      </p>
                    </WorkflowRow>
                  </>
                )}

                {tutorApplication.mayApproveInterview && (
                  <div>
                    <ApproveInterview id={id} />
                  </div>
                )}
                {tutorApplication.mayRejectInterview && (
                  <div>
                    <RejectInterview id={id} />
                  </div>
                )}
                {tutorApplication.mayRevertToInterviewScheduled && (
                  <div>
                    <RevertToInterviewScheduled id={id} />
                  </div>
                )}
                <hr />
              </section>

              <section>
                {tutorApplication.approvedAt && (
                  <>
                    <WorkflowRow>
                      <span className="font-medium">Applicant Approved</span>
                      <LocalTime timestamp={tutorApplication.approvedAt} />
                    </WorkflowRow>

                    <WorkflowRow>
                      <span className="font-medium">Applicant Approved By</span>
                      {tutorApplication.approvedBy?.fullName}
                    </WorkflowRow>
                  </>
                )}

                {tutorApplication.deferredAt && (
                  <>
                    <WorkflowRow>
                      <span className="font-medium">Applicant Deferred</span>
                      <LocalTime timestamp={tutorApplication.deferredAt} />
                    </WorkflowRow>

                    <WorkflowRow>
                      <span className="font-medium">Applicant Deferred By</span>
                      {tutorApplication.deferredBy?.fullName}
                    </WorkflowRow>

                    <WorkflowRow>
                      <span className="font-medium">Deferment Reason</span>
                      <p>{tutorApplication.defermentReason}</p>
                    </WorkflowRow>
                  </>
                )}
              </section>

              <section>
                {tutorApplication.applicationState ===
                  "pending_final_approval" && (
                  <ResendOnboardingInstructions id={id} />
                )}
                {tutorApplication.mayApproveApplicant && (
                  <div>
                    <ApproveApplicant id={id} />
                  </div>
                )}

                {tutorApplication.mayDeferApplicant &&
                  tutorApplication.applicationState !== "deferred" &&
                  tutorApplication.applicationState !== "approved" &&
                  !tutorApplication.applicationState.match(/rejected/) && (
                    <div>
                      <DeferApplicant id={id} />
                    </div>
                  )}
              </section>
            </div>
          </div>
        )}
      </UrqlLoadingIndicator>
    </>
  )
}

const tutorApplicationQuery = gql`
  fragment AdminFields on Admin {
    id
    fullName
    email
  }
  fragment SubjectBucketFields on SubjectBucket {
    id
    name
    subjectType
    tier {
      id
      gradeRange
      order
    }
  }
  query ($id: ID!) {
    tutorApplication(id: $id) {
      id
      applicationState
      editUrl
      firstName
      lastName
      fullName
      email
      phoneNumber
      country
      region
      timeZone
      referralSource
      referralSourceOther
      adminNotes
      formattedAdminNotes
      resume {
        url
        filename
      }
      selfieExternalUrl
      selfie {
        url
        filename
      }
      currentAssignee {
        ... on Admin {
          id
          fullName
        }
        ... on TutorApplication {
          id
          fullName
        }
      }

      # demographics
      canCompleteFingerprint
      backgroundCheckNotes

      # education
      certificates
      noCollegeEducation
      degrees {
        id
        college
        year
        country
        degree
        degreeType
        level
        levelOther
        inUsa
        diplomaMill
        transcript {
          filename
          downloadUrl
          url
        }
      }
      foreignLanguageProficiencies {
        id
        fluency
        academicAbility
        foreignLanguage {
          id
          name
        }
      }

      # technology
      techSavviness
      highSpeedInternet
      hasTablet
      willPurchaseTablet
      canDownloadFiles
      canUseCommonWebapps
      canTroubleshootWebcam
      canTroubleshootLogin

      # teaching experience
      teachingExperienceYears
      teachingExperienceK2
      teachingExperienceK2Other
      teachingExperience35
      teachingExperience35Other
      teachingExperience68
      teachingExperience68Other
      teachingExperience912
      teachingExperience912Other
      teachingExperienceCollege
      teachingExperienceCollegeOther
      learningDifferencesTeachingAbility
      learningDifferencesExperienceNotes
      behavioralDifferencesTeachingAbility
      behavioralDifferencesExperienceNotes
      englishLanguageLearnersTeachingAbility
      englishLanguageLearnersExperienceNotes
      teachingStyle
      teachingReason
      camaraderieNotes
      parentCommunicationAbility
      yearsSinceLastTeachingExperience

      # subjects
      mathSubjectBuckets: subjectBuckets(subjectType: math) {
        ...SubjectBucketFields
      }
      scienceSubjectBuckets: subjectBuckets(subjectType: science) {
        ...SubjectBucketFields
      }
      englishSubjectBuckets: subjectBuckets(subjectType: english) {
        ...SubjectBucketFields
      }
      socialStudiesSubjectBuckets: subjectBuckets(subjectType: social_studies) {
        ...SubjectBucketFields
      }
      languagesSubjectBuckets: subjectBuckets(subjectType: languages) {
        ...SubjectBucketFields
      }
      otherSubjectBuckets: subjectBuckets(subjectType: other) {
        ...SubjectBucketFields
      }

      # availability
      availabilities {
        id
        startsAt
        endsAt
        weekday
        weekdayNumber
      }

      # workflow details
      resumeSubmittedAt
      resumeReviewStartedAt
      resumeReviewer {
        ...AdminFields
      }
      resumeApprovedAt
      resumeApprovedBy {
        ...AdminFields
      }
      resumeRejectedAt
      resumeRejectedBy {
        ...AdminFields
      }
      resumeRejectionReason
      selfieSubmittedAt
      selfieReviewStartedAt
      selfieReviewer {
        ...AdminFields
      }
      selfieApprovedAt
      selfieApprovedBy {
        ...AdminFields
      }
      selfieRejectedAt
      selfieRejectedBy {
        ...AdminFields
      }
      selfieRejectionReason
      interviewScheduledAt
      interviewScheduledFor
      tutorApplicationInterviewer {
        id
        name
        admin {
          fullName
        }
      }
      interviewApplicantNotes
      interviewRejectedAt
      interviewRejectedBy {
        ...AdminFields
      }
      interviewRejectionReason
      approvedAt
      approvedBy {
        ...AdminFields
      }
      deferredAt
      defermentReason
      deferredBy {
        ...AdminFields
      }

      # workflow actions
      mayStartResumeReview
      mayApproveResume
      mayRejectResume
      mayStartSelfieReview
      mayApproveSelfie
      mayRejectSelfie
      mayRejectInterview
      mayApproveInterview
      mayApproveApplicant
      mayDeferApplicant

      mayRevertToDraft
      mayRevertToResumeSubmitted
      mayRevertToSelfieSubmitted
      mayRevertToInterviewScheduled

      tutor {
        id
        showPath
      }
    }
  }
`

export default TutorApplicationDetails
