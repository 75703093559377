import React from "react"

import { buildQuery, useQuery } from "micro-graphql-react"
import PropTypes from "prop-types"

import { tutorClient } from "src/graphql-config"

import PieChart from "components/TailoredSectionResultChart"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import loadResultsQuery from "./loadResultsQuery"

const TailoredAssignmentResults = props => {
  const { tailoredAssignmentId, studentId } = props

  const loadingState = useQuery(
    buildQuery(
      loadResultsQuery,
      { tailoredAssignmentId, studentId },
      { client: tutorClient }
    )
  )

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading assignment results"
    >
      {({ data }) => {
        const chartData = data.tailoredSectionSummaries
          .map(tailoredSectionSummary => {
            const score = `${(tailoredSectionSummary.score * 100).toFixed(2)}%`
            return {
              sectionId: tailoredSectionSummary.tailoredSection.id,
              sectionReviewPath: tailoredSectionSummary.reviewPath,
              label: `${tailoredSectionSummary.tailoredSection.name}\n${score}`,
              data: [
                { x: "Correct", y: tailoredSectionSummary.correctCount },
                { x: "Incorrect", y: tailoredSectionSummary.incorrectCount },
              ].filter(d => d.y > 0),
            }
          })
          .filter(d => d.data.length > 0)

        return (
          <React.Fragment>
            <h1 className="center">
              Results for {data.tailoredAssignment.name}
            </h1>
            <div className="row">
              {chartData.map((chartData, index) => (
                <div className="col-1-2 center" key={chartData.sectionId}>
                  <PieChart chartData={chartData} />
                  <div>
                    <a
                      className="btn solid orange"
                      href={chartData.sectionReviewPath}
                    >
                      Review Answers
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        )
      }}
    </WithLoadingIndicator>
  )
}

TailoredAssignmentResults.propTypes = {
  tailoredAssignmentId: PropTypes.number.isRequired,
  studentId: PropTypes.number.isRequired,
}

export default TailoredAssignmentResults
