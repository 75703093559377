import React from "react"

import { buildQuery, useQuery } from "micro-graphql-react"
import PropTypes from "prop-types"

import WithLoadingIndicator from "components/WithLoadingIndicator"

import TailoredSection from "./TailoredSection"
import loadAssignmentQuery from "./loadAssignmentQuery"

const TailoredAssignmentPreview = props => {
  const { id, client } = props

  let loadingState = useQuery(
    buildQuery(loadAssignmentQuery, { id }, { client })
  )

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading assignment"
    >
      {({ data }) =>
        data.tailoredAssignment.tailoredSections.map(tailoredSection => (
          <TailoredSection
            key={tailoredSection.id}
            tailoredSection={tailoredSection}
          />
        ))
      }
    </WithLoadingIndicator>
  )
}

TailoredAssignmentPreview.propTypes = {
  id: PropTypes.number.isRequired,
  client: PropTypes.object.isRequired,
}

export default TailoredAssignmentPreview
