import React, { useEffect, useState } from "react"

import { Field, Form, Formik } from "formik"

import axios from "src/axios"

const TailoredSection = props => {
  const { id } = props
  const [section, setSection] = useState(null)
  const [formState, setFormState] = useState({ questions: [] })

  useEffect(() => {
    axios.get(`/api/students/tailored_sections/${id}`).then(response => {
      const section = response.data
      setSection(section)
      const defaultState = { questions: [] }
      section.questions.forEach(question =>
        defaultState.questions.push({
          questionId: question.id,
          studentAnswerId: question.studentAnswer.id,
          answerId: question.studentAnswer.answerId,
        })
      )
      setFormState(defaultState)
    })
  }, [id])

  function handleSubmit(values, actions) {
    const answersAttributes = values.questions.map(question => {
      if (!question.answerId) return null
      return {
        id: question.studentAnswerId,
        tailored_question_id: question.questionId,
        tailored_answer_id: question.answerId,
      }
    })
    const formParams = {
      tailored_section: {
        tailored_student_answers_attributes: answersAttributes,
      },
    }
    axios
      .patch(`/api/students/tailored_sections/${id}`, formParams)
      .then(response => {
        const { success, redirectTo } = response.data
        actions.setSubmitting(false)
        if (success === true) {
          window.location = redirectTo
        } else {
          actions.setStatus("Something went wrong")
        }
      })
  }

  if (section === null) return ""

  const hasNextSection = section.nextSection ? true : false
  const hasPreviousSection = section.previousSection ? true : false

  return (
    <div>
      <h3>{section.name}</h3>
      <Formik
        initialValues={formState}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, status, isSubmitting, setFieldValue }) => (
          <Form>
            <React.Fragment>
              {section.questions.map((question, index) => (
                <div
                  className="tailored-section-review__question-group"
                  key={question.id}
                >
                  <h5>
                    {question.type === "text" ? (
                      question.text
                    ) : (
                      <img
                        src={question.image.url}
                        alt={question.image.fileName}
                      />
                    )}
                  </h5>
                  <hr />
                  <ul>
                    {question.answers.map(answer => (
                      <li key={answer.id}>
                        <label>
                          <Field
                            type="radio"
                            name={`questions.${index}.answerId`}
                            value={answer.id}
                            defaultChecked={
                              answer.id === question.studentAnswer.answerId
                            }
                          />
                          {answer.type === "text" ? (
                            answer.text
                          ) : (
                            <img
                              src={answer.image.url}
                              alt={answer.image.fileName}
                            />
                          )}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}

              <div className="row">
                {hasPreviousSection && (
                  <div className="col-1-12">
                    <a
                      href={section.previousSection.path}
                      className="btn solid orange"
                    >
                      Go back to {section.previousSection.name}
                    </a>
                  </div>
                )}
                <div className="col-1-12">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn solid blue"
                  >
                    {hasNextSection
                      ? `Next: ${section.nextSection.name}`
                      : "Complete Assignment"}
                  </button>
                </div>
                <div className="col-2-12">
                  {status && status.msg && <div>{status.msg}</div>}
                </div>
              </div>
            </React.Fragment>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default TailoredSection
