import React, { useState } from "react"

import { gql, useMutation, useQuery } from "hooks/urql"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import TagSelect from "components/Forms/TagSelect"
import Pagination from "components/Pagination"
import TailoredQuestionPreview from "components/TailoredAssignmentPreview/TailoredQuestion"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const TailoredQuestionsIndex = () => {
  const [page, setPage] = useState(1)
  const [tags, setTags] = useState([])

  const [, runMutation] = useMutation(removeQuestionMutation)
  const [result] = useQuery({
    query: questionsQuery,
    variables: { page, tagList: tags.map(tag => tag.label) },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data: { tailoredQuestions } }) => (
        <>
          <div className="row">
            <div className="col-8">
              <a
                className="btn btn-success"
                href="/admin/tailored_questions/new"
              >
                New Question
              </a>
              <a
                className="btn btn-success"
                href="/admin/tailored_question_groups/new"
              >
                New Question Group
              </a>
              <a
                className="btn btn-info"
                href="/admin/tailored_question_groups"
              >
                View Question Groups
              </a>
            </div>
            <div className="col-4">
              <div>
                <label className="form-label">Tags</label>
                <AutocompleteSelect
                  api="/api/admins/tailored_questions/autocomplete_tags"
                  isMulti
                  value={tags}
                  onChange={selected => setTags(selected)}
                />
              </div>
            </div>
          </div>

          {tailoredQuestions.data.map(question => (
            <React.Fragment key={question.id}>
              <TailoredQuestionPreview tailoredQuestion={question} />

              <TagSelect
                api="/api/admins/tailored_questions/autocomplete_tags"
                tagList={question.tags.map(tag => ({
                  value: tag.id,
                  label: tag.name,
                }))}
                modelKey="tagId"
                recordKey="tailoredQuestionId"
                recordId={question.id}
                createMutation={createTagMutation}
                addMutation={addTagMutation}
                removeMutation={removeTagMutation}
              />

              <a className="btn btn-warning" href={question.editPath}>
                Edit
              </a>

              <button
                className="btn btn-danger"
                onClick={() => runMutation({ id: question.id })}
              >
                Remove
              </button>
            </React.Fragment>
          ))}
          <hr />
          <Pagination
            totalPages={tailoredQuestions.totalPages}
            currentPage={tailoredQuestions.currentPage}
            changePage={setPage}
          />
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const questionsQuery = gql`
  query ($page: Int, $tagList: [String!]) {
    tailoredQuestions(page: $page, tagList: $tagList) {
      currentPage
      totalPages
      data {
        id
        tags {
          id
          name
        }
        editPath
        type
        text
        image {
          url
          filename
        }
        diagram {
          url
          filename
        }
        tailoredAnswers {
          id
          type
          text
          correct
          image {
            url
            filename
          }
        }
      }
    }
  }
`

const removeQuestionMutation = gql`
  mutation ($id: ID!) {
    removeTailoredQuestion(id: $id) {
      success
      tailoredQuestion {
        id
      }
    }
  }
`

const createTagMutation = gql`
  mutation ($name: String!, $tailoredQuestionId: ID!) {
    tailoredQuestionCreateTag(
      name: $name
      tailoredQuestionId: $tailoredQuestionId
    ) {
      tailoredQuestion {
        id
      }
      failures {
        message
      }
    }
  }
`

const addTagMutation = gql`
  mutation ($tagId: ID!, $tailoredQuestionId: ID!) {
    tailoredQuestionAddTag(
      tagId: $tagId
      tailoredQuestionId: $tailoredQuestionId
    ) {
      tailoredQuestion {
        id
      }
      failures {
        message
      }
    }
  }
`

const removeTagMutation = gql`
  mutation ($tagId: ID!, $tailoredQuestionId: ID!) {
    tailoredQuestionRemoveTag(
      tagId: $tagId
      tailoredQuestionId: $tailoredQuestionId
    ) {
      tailoredQuestion {
        id
      }
      failures {
        message
      }
    }
  }
`

export default TailoredQuestionsIndex
