import React, { useState } from "react"

import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"

import ordered from "src/ordered"

import EditableDisplay from "components/Forms/EditableDisplay"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import CourseSection from "./CourseSection"
import NewSection from "./NewSection"

const CourseDetails = ({ id }) => {
  const [errors, setErrors] = useState()
  const { runMutation } = useMutation(buildMutation(updateCourseMutation))
  const loadingState = useQuery(
    buildQuery(
      courseQuery,
      { id },
      {
        onMutation: [
          {
            when: "createCourseSection",
            run: ({ softReset, currentResults }, response) => {
              const { courseSection } = response.createCourseSection
              if (courseSection) {
                currentResults.course.courseSections.push(courseSection)
                softReset(currentResults)
              }
            },
          },
          {
            when: "removeCourseSection",
            run: ({ softReset, currentResults }, response) => {
              const { courseSection } = response.removeCourseSection
              if (courseSection) {
                currentResults.course.courseSections =
                  currentResults.course.courseSections.filter(
                    i => i.id !== courseSection.id
                  )
                softReset(currentResults)
              }
            },
          },
          {
            when: "updateCourse",
            run: ({ softReset, currentResults }, response) => {
              const { errorMessages, course } = response.updateCourse
              if (errorMessages) {
                setErrors(errorMessages)
              } else {
                currentResults.course.durationWeeks = course.durationWeeks
                currentResults.course.sessionsPerWeek = course.sessionsPerWeek
                currentResults.course.minimumStudyGroupSize =
                  course.minimumStudyGroupSize
                currentResults.course.maximumStudyGroupSize =
                  course.maximumStudyGroupSize
                softReset(currentResults)
              }
            },
          },
          {
            when: "updateCourseSection",
            run: ({ softReset, currentResults }, response) => {
              const { errorMessages, courseSection } =
                response.updateCourseSection
              if (!courseSection) return
              const sectionIndex =
                currentResults.course.courseSections.findIndex(
                  s => s.id === courseSection.id
                )
              const section = currentResults.course.courseSections[sectionIndex]
              if (errorMessages) {
                section.errors = errorMessages
              } else {
                section.errors = null
                section.startsOn = courseSection.startsOn
                section.endsOn = courseSection.endsOn
                section.studentRegistrationEndsAt =
                  courseSection.studentRegistrationEndsAt
                section.groupLimit = courseSection.groupLimit
                section.tutorRegistrationOpen =
                  courseSection.tutorRegistrationOpen
                section.studyGroupsRebalanced =
                  courseSection.studyGroupsRebalanced
              }
              currentResults.course.courseSections[sectionIndex] = section
              softReset(currentResults)
            },
          },
          {
            when: "courseSectionCreateAvailabilities",
            run: ({ softReset, currentResults }, response) => {
              const { errorMessages, courseSection } =
                response.courseSectionCreateAvailabilities
              if (errorMessages) return

              const sectionIndex =
                currentResults.course.courseSections.findIndex(
                  s => s.id === courseSection.id
                )
              currentResults.course.courseSections[
                sectionIndex
              ].availabilities = courseSection.availabilities
              softReset(currentResults)
            },
          },
          {
            when: "courseSectionRemoveAvailability",
            run: ({ softReset, currentResults }, response) => {
              const { errorMessages, courseSection } =
                response.courseSectionRemoveAvailability
              if (errorMessages) return

              const sectionIndex =
                currentResults.course.courseSections.findIndex(
                  s => s.id === courseSection.id
                )
              currentResults.course.courseSections[
                sectionIndex
              ].availabilities = courseSection.availabilities
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <div className="row">
            <div className="col">
              {errors && <div className="alert alert-danger">{errors}</div>}
              <dl>
                <dt>Cluster Name</dt>
                <dd>{data.course.courseCluster.name}</dd>

                <dt>Duration</dt>
                <dd>
                  <EditableDisplay
                    type="number"
                    displayValue={`${data.course.durationWeeks} Weeks`}
                    editableValue={data.course.durationWeeks}
                    save={({ value }) =>
                      runMutation({ id: id, durationWeeks: parseInt(value) })
                    }
                  />
                </dd>

                <dt>Sessions per Week</dt>
                <dd>
                  <EditableDisplay
                    type="number"
                    displayValue={data.course.sessionsPerWeek}
                    save={({ value }) =>
                      runMutation({ id: id, sessionsPerWeek: parseInt(value) })
                    }
                  />
                </dd>

                <dt>Minimum Students per Group</dt>
                <dd>
                  <EditableDisplay
                    type="number"
                    displayValue={data.course.minimumStudyGroupSize}
                    save={({ value }) =>
                      runMutation({
                        id: id,
                        minimumStudyGroupSize: parseInt(value),
                      })
                    }
                  />
                </dd>

                <dt>Maximum Students per Group</dt>
                <dd>
                  <EditableDisplay
                    type="number"
                    displayValue={data.course.maximumStudyGroupSize}
                    save={({ value }) =>
                      runMutation({
                        id: id,
                        maximumStudyGroupSize: parseInt(value),
                      })
                    }
                  />
                </dd>

                {data.course.prepaidPrice && (
                  <>
                    <dt>Prepaid Price</dt>
                    <dd>${data.course.prepaidPrice}</dd>
                  </>
                )}

                <dt>Organization</dt>
                <dd>
                  {data.course.organization ? (
                    <a href={data.course.organization.showPath}>
                      {data.course.organization.name}
                    </a>
                  ) : (
                    "Public"
                  )}
                </dd>

                <dt>Subject</dt>
                <dd>{data.course.subject.name}</dd>

                <dt>Grades</dt>
                <dd>
                  {ordered(data.course.grades, "number").map(grade => (
                    <div key={grade.id}>{grade.name}</div>
                  ))}
                </dd>
              </dl>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <hr />
              <h3>Sections</h3>
              {ordered(data.course.courseSections, "startsOn").map(
                courseSection => (
                  <CourseSection
                    key={courseSection.id}
                    course={data.course}
                    courseSection={courseSection}
                  />
                )
              )}
              <NewSection course={data.course} />
            </div>
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const courseQuery = compress`
  query($id: ID!) {
    course(id: $id) {
      id
      durationWeeks
      sessionsPerWeek
      minimumStudyGroupSize
      maximumStudyGroupSize
      prepaidPrice
      courseCluster {
        name
      }
      organization {
        id
        name
        showPath
      }
      subject {
        name
      }
      grades {
        id
        name
        number
      }
      courseSections {
        id
        startsOn
        endsOn
        studentRegistrationEndsAt
        groupLimit
        tutorRegistrationOpen
        studyGroupsRebalanced
        availabilities {
          id
          startsAt
          endsAt
          weekday
        }
      }
    }
  }
`

const updateCourseMutation = compress`
  mutation(
    $id: ID!
    $durationWeeks: Int
    $sessionsPerWeek: Int
    $minimumStudyGroupSize: Int
    $maximumStudyGroupSize: Int
  ) {
    updateCourse(
      id: $id
      durationWeeks: $durationWeeks
      sessionsPerWeek: $sessionsPerWeek
      minimumStudyGroupSize: $minimumStudyGroupSize
      maximumStudyGroupSize: $maximumStudyGroupSize
    ) {
      errorMessages
      course {
        id
        durationWeeks
        sessionsPerWeek
        minimumStudyGroupSize
        maximumStudyGroupSize
      }
    }
  }
`

export default CourseDetails
