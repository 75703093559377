import React, { memo, useEffect, useState } from "react"

import axios from "src/axios"

function MatchingQuestionsCount(props) {
  const { apiNamespace } = props
  const [count, setCount] = useState("")
  useEffect(() => {
    if (props.tags.length === 0) {
      setCount("")
    } else {
      axios
        .get(`/api/${apiNamespace}/tailored_questions`, {
          params: { tags: props.tags },
        })
        .then(response => {
          setCount(response.data.total)
        })
    }
  }, [apiNamespace, props.tags])

  return <span>Matching Questions: {count}</span>
}

export default memo(MatchingQuestionsCount, (prev, next) => {
  return prev.tags.sort().join() === next.tags.sort().join()
})
