import React from "react"

import * as colors from "styles/colors"
import {
  VictoryContainer,
  VictoryGroup,
  VictoryLabel,
  VictoryPie,
  VictoryTheme,
  VictoryTooltip,
} from "victory"

import styles from "./styles.module.scss"

const CustomLabel = props => {
  return (
    <g>
      <VictoryLabel {...props} />
      <VictoryTooltip
        {...props}
        x={175}
        y={225}
        text={Math.round(props.datum.y)}
        orientation="top"
        pointerLength={0}
        cornerRadius={50}
        width={100}
        height={100}
        flyoutStyle={{ fill: colors.blue, stroke: colors.blue }}
        style={{ fontSize: 16, fill: "white" }}
      />
    </g>
  )
}
CustomLabel.defaultEvents = VictoryTooltip.defaultEvents

const TailoredSectionResultChart = props => {
  const { chartData } = props
  const overallColors = {
    Correct: colors.green,
    Incorrect: colors.red,
  }
  const tagColors = [
    "#F4511E",
    "#FFF59D",
    "#DCE775",
    "#8BC34A",
    "#00796B",
    "#006064",
  ]

  return (
    <div className={styles.container}>
      <VictoryGroup
        containerComponent={<VictoryContainer style={{ height: "auto" }} />}
        theme={VictoryTheme.material}
        style={{
          data: {
            fill: d => {
              if (overallColors[d.x]) {
                return overallColors[d.x]
              } else {
                const index = d.i % tagColors.length
                return tagColors[index]
              }
            },
          },
        }}
      >
        <VictoryPie
          data={chartData.data}
          labelComponent={<CustomLabel />}
          innerRadius={75}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 20 }}
          x={175}
          y={170}
          text={chartData.label}
        />
      </VictoryGroup>
    </div>
  )
}

export default TailoredSectionResultChart
