import React from "react"

import LocalTime from "components/LocalTime"

const CourseDetails = ({ studyGroup }) => (
  <div className="col-12">
    <h4>Course Details</h4>
    <dl>
      <dt>Course</dt>
      <dd>
        <a href={studyGroup.course.showPath}>
          {studyGroup.course.courseCluster.name}
        </a>
      </dd>

      <dt>Starts On</dt>
      <dd>
        <LocalTime timestamp={studyGroup.courseSection.startsOn} omitTime />
      </dd>

      <dt>Ends On</dt>
      <dd>
        <LocalTime timestamp={studyGroup.courseSection.endsOn} omitTime />
      </dd>

      <dt>Weekly Availabilities</dt>
      <dd>
        <ul className="list-unstyled">
          {studyGroup.courseSection.availabilities.map(availability => (
            <li key={availability.id}>
              {availability.weekday}:&nbsp;
              <LocalTime timestamp={availability.startsAt} omitDate />
              &nbsp;&mdash;&nbsp;
              <LocalTime timestamp={availability.endsAt} omitDate />
            </li>
          ))}
        </ul>
      </dd>
    </dl>
  </div>
)

export default CourseDetails
