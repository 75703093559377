import React, { useState } from "react"

const InlineConfirmation = ({ handleSubmit, buttonText, type = "success" }) => {
  const [pending, setPending] = useState(false)
  return pending ? (
    <>
      <button
        type="button"
        onClick={handleSubmit}
        className="btn btn-success rounded-none rounded-l-[3px]"
      >
        Confirm
      </button>

      <button
        type="button"
        onClick={() => setPending(false)}
        className="btn btn-danger -ml-px rounded-none rounded-r-[3px]"
      >
        Cancel
      </button>
    </>
  ) : (
    <button
      type="button"
      onClick={() => setPending(true)}
      className={`btn btn-${type}`}
    >
      {buttonText}
    </button>
  )
}

export default InlineConfirmation
