import React, { useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"

import PieChart from "components/TailoredSectionResultChart"

const TailoredAssignmentOverallResults = props => {
  const { tailoredAssignmentId, assignmentName, studentName } = props
  const [tag, setTag] = useState({ name: "Overall" })

  const { loaded, data } = useQuery(
    buildQuery(sectionSummariesQuery, {
      tailoredAssignmentId,
      tagId: tag.id,
    })
  )

  const chartData = loaded
    ? data.tailoredAssignment.tailoredSectionSummaries
        .map(tailoredSectionSummary => {
          return {
            sectionId: tailoredSectionSummary.tailoredSection.id,
            sectionName: tailoredSectionSummary.tailoredSection.name,
            label: tag.name,
            averageScore: `${(
              tailoredSectionSummary.averageScore * 100
            ).toFixed(2)}%`,
            data: [
              { x: "Correct", y: tailoredSectionSummary.correctCount },
              { x: "Incorrect", y: tailoredSectionSummary.incorrectCount },
            ].filter(d => d.y > 0),
          }
        })
        .filter(d => d.data.length > 0)
    : []

  return (
    <React.Fragment>
      <h3 className="text-center">
        {studentName} Results for {assignmentName}
      </h3>
      <div className="text-center">
        <button className="btn" onClick={() => setTag({ name: "Overall" })}>
          Overall
        </button>
        {loaded &&
          data.tailoredAssignment.tags.map(tag => (
            <button key={tag.id} className="btn" onClick={() => setTag(tag)}>
              {tag.name}
            </button>
          ))}
      </div>
      <div className="row">
        {chartData.map((chartData, index) => (
          <div className="col-md-6 col-sm-12" key={chartData.sectionId}>
            <div className="text-center">
              <h5>
                {chartData.sectionName}
                <br />
                Average Score: {chartData.averageScore}
              </h5>
              <PieChart chartData={chartData} />
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}

const sectionSummariesQuery = compress`
  query($tailoredAssignmentId: ID!, $tagId: ID) {
    tailoredAssignment(id: $tailoredAssignmentId) {
      tags {
        id
        name
      }
      tailoredSectionSummaries(tagId: $tagId) {
        tailoredSection {
          id
          name
        }
        correctCount
        incorrectCount
        averageScore
      }
    }
  }
`

export default TailoredAssignmentOverallResults
