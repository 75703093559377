import React from "react"

import { useField } from "formik"

import ErrorMessage from "./ErrorMessage"
import "./checkbox-styles.scss"

const CheckboxField = ({
  hideError = false,
  afterChange = undefined,
  ...props
}) => {
  const [field, meta] = useField(props)

  return (
    <div className="form-group">
      <div className="form-check">
        <label
          className={`form-check-label ${props.disabled ? "disabled" : ""}`}
        >
          {props.label}
          <input
            {...field}
            onChange={e => {
              field.onChange(e)
              typeof afterChange === "function" && afterChange()
            }}
            className="form-check-input"
            type="checkbox"
            checked={
              props.value ? field.value.includes(props.value) : field.value
            }
            {...props}
          />
          <span className="form-check-sign" />
        </label>
      </div>
      {!hideError && <ErrorMessage {...meta} />}
    </div>
  )
}

export default CheckboxField
