import React from "react"

import { css } from "@emotion/core"

import Checkbox from "components/Forms/Checkbox"

const TailoredQuestion = props => {
  const { type, text, image, tailoredAnswers, diagram } = props.tailoredQuestion

  const questionTitle =
    type === "text" ? (
      <h5>{text}</h5>
    ) : (
      <img src={image.url} alt={image.fileName} />
    )
  const questionDiagram = diagram ? (
    <img src={diagram.url} alt={diagram.fileName} />
  ) : (
    ""
  )

  const questionAnswers = tailoredAnswers.map(tailoredAnswer => {
    const { id, type, image, text, correct } = tailoredAnswer
    const answerContent =
      type === "text" ? text : <img src={image.url} alt="answers" />

    return (
      <li key={id}>
        <Checkbox value="" checked={correct} disabled={true}>
          {answerContent}
        </Checkbox>
      </li>
    )
  })

  return (
    <React.Fragment>
      <hr />
      <div
        className="row"
        css={css`
          font-family: "SourceCodePro", Helvetica, Arial, sans-serif;
          font-variant-numeric: slashed-zero;
        `}
      >
        <div className="col-4">
          <ul className="list-unstyled">{questionAnswers}</ul>
        </div>
        <div className="col-8">
          <div>{questionTitle}</div>
          <div>{questionDiagram}</div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TailoredQuestion
