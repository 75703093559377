import { compress } from "micro-graphql-react"

export default compress`
  query($tailoredAssignmentId: ID!) {
    tailoredAssignment(id: $tailoredAssignmentId) {
      name
    }
    tailoredSectionSummaries(tailoredAssignmentId: $tailoredAssignmentId) {
      correctCount
      incorrectCount
      score
      reviewPath
      tailoredSection {
        id
        name
      }
    }
  }
`
