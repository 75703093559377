import React, { useState } from "react"

import ordered from "src/ordered"

import { gql, useQuery } from "hooks/urql"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

const TailoredQuestionGroupsIndex = () => {
  const [page, setPage] = useState(1)
  const [tags, setTags] = useState([])
  const [result] = useQuery({
    query: questionGroupsQuery,
    variables: { page, tagList: tags.map(tag => tag.label) },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => (
        <>
          <div className="mb-3">
            <label className="form-label">Tags</label>
            <AutocompleteSelect
              api="/api/admins/tailored_question_groups/autocomplete_tags"
              isMulti
              value={tags}
              onChange={selected => setTags(selected)}
            />
          </div>
          <table className="table">
            <thead>
              <th>Internal Name</th>
              <th>Diagrams</th>
              <th>Tag List</th>
              <th>Questions</th>
            </thead>
            <tbody>
              {data.tailoredQuestionGroups.data.map(group => (
                <tr key={group.id}>
                  <td>
                    <a href={group.editPath}>{group.internalName}</a>
                  </td>
                  <td>
                    {group.diagrams.map(diagram => (
                      <img
                        key={diagram.thumbUrl}
                        src={diagram.thumbUrl}
                        alt={diagram.filename}
                        className="mr-2"
                      />
                    ))}
                  </td>
                  <td>{ordered(group.tagList).join(", ")}</td>
                  <td>
                    <ul className="list-unstyled">
                      {group.tailoredQuestions.map(question => (
                        <li key={question.id}>
                          <a href={question.editPath}>
                            {question.type === "text" ? (
                              question.text
                            ) : (
                              <img
                                src={question.image.thumbUrl}
                                alt={question.image.filename}
                              />
                            )}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Pagination
            currentPage={data.tailoredQuestionGroups.currentPage}
            totalPages={data.tailoredQuestionGroups.totalPages}
            changePage={setPage}
          />
        </>
      )}
    </UrqlLoadingIndicator>
  )
}

const questionGroupsQuery = gql`
  query TailoredQuestionGroups($page: Int, $tagList: [String!]) {
    tailoredQuestionGroups(page: $page, tagList: $tagList) {
      totalPages
      currentPage
      data {
        id
        internalName
        tagList
        editPath
        diagrams {
          thumbUrl
          filename
        }
        tailoredQuestions {
          id
          type
          text
          editPath
          image {
            thumbUrl
            filename
          }
        }
      }
    }
  }
`
export default TailoredQuestionGroupsIndex
